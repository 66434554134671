import LOGO from '../assets/SIMBOLO_BRANCO_CHRONOS.png';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SellIcon from '@mui/icons-material/Sell';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SettingsIcon from '@mui/icons-material/Settings';
import useRouter from '../adapters/userouter';

export default function SidebarContent() {
  const { goToPage } = useRouter();
  const iterateOverPanel = [
    {
      title: 'Dashboard',
      icon: <DashboardIcon style={{ color: 'white' }} />,
      goTo: '/dashboard/',
      color: 'white',
    },
    {
      title: 'Recarga',
      icon: <MonetizationOnIcon style={{ color: 'green' }} />,
      goTo: '/recarga/',
      color: 'green',
    },
    {
      title: 'Indique',
      icon: <GroupAddIcon style={{ color: 'lightblue' }} />,
      goTo: '/affiliates/',
      color: 'lightblue',
    },
    {
      title: 'Pedidos',
      icon: <LocalShippingIcon style={{ color: 'orange' }} />,
      goTo: '/pedidos/',
      color: 'orange',
    },
  ];

  const iterateOverSettings = [
    {
      title: 'Integrações',
      icon: <SettingsIcon />,
      goTo: '/integrate/',
    },
    {
      title: 'Envios (Em breve)',
      icon: <LocalShippingIcon />,
      goTo: '',
    },
    {
      title: 'Taxação (Em breve)',
      icon: <SellIcon />,
      goTo: '',
    },
  ];

  return (
    <div className='py-10'>
      <div className='px-14 hidden md:block'>
        <span className=''>
          <img className='w-50 h-40' src={LOGO} alt='Chronos Logo' />
        </span>
      </div>
      <div className='text-[#5f6270] list-none px-6'>
        <p className='text-[13px] -mx-1'>Painel</p>
        <ul className='list-none p-0'>
          {iterateOverPanel &&
            iterateOverPanel.map((item) => (
              <li className='flex items-center no-underline gap-2'>
                {item.icon}
                <a
                  onClick={() => goToPage(`${item.goTo}`)}
                  className='no-underline hover:cursor-pointer'
                >
                  <p className='text-sm' style={{ color: item.color }}>
                    {item.title}
                  </p>
                </a>
              </li>
            ))}
          <p className='text-[13px] -mx-1'>Configurações</p>
          {iterateOverSettings &&
            iterateOverSettings.map((item) => (
              <li className='flex items-center no-underline gap-2'>
                {item.icon}
                <a
                  onClick={() => goToPage(`${item.goTo}`)}
                  className='no-underline'
                >
                  <p className='text-sm text-[#878a99] hover:text-slate-300'>
                    {item.title}
                  </p>
                </a>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}
