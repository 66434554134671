import { Outlet } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';
import { useEffect, useRef, useState } from 'react';

export default function OutletHeader() {
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        setOpen(!open);
      }
    };
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);
  return (
    <>
      <Sidebar isOpen={open} ref={sidebarRef} />
      <Header toOpen={setOpen} />
      <Outlet />
    </>
  );
}
