import { ReactNode, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

type FormVProps = {
    children: ReactNode;
    itemId?: string | number;
    className?: string;
    formSubmitCallback?: any;
    validate: 'all' | 'onBlur' | 'onChange' | 'onSubmit' | 'onTouched';
    resetOnSubmit?: boolean;
    onResetForm?: boolean;
    sanitizeEmptyFields?: boolean;
    defaultValue?: any;
}

export default function ChronosForm(props: FormVProps){
    const methods = useForm({
        mode: props.validate || 'onSubmit',
        shouldUnregister: false,
        defaultValues: props?.defaultValue || {},
    });

    const {
        className = '',
        children,
        formSubmitCallback,
        itemId,
        resetOnSubmit = false,
        onResetForm = false,
        sanitizeEmptyFields = false
    } = props;

    const resetForm = () => {
        methods.reset(props?.defaultValue, {
            keepDefaultValues: false,
            keepSubmitCount: true
        });
    };

    useEffect(() => {
        if (onResetForm) resetForm();
    }, [onResetForm]);

    return (
        <FormProvider {...methods}>
            <form
                className={className}
                onSubmit={methods.handleSubmit((data: {[x: string]: any}, e: any) => {
                    if (sanitizeEmptyFields) {
                        Object.keys(data).forEach((key) => {
                            if (data && data[key] === ''){
                                data[key] = null;
                            }
                        });
                    }
                    formSubmitCallback(data, e, itemId);
                    if (resetOnSubmit){
                        resetForm();
                    }
                })}
            >
                {children}
            </form>
        </FormProvider>
    );
}