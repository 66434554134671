import Person2Icon from '@mui/icons-material/Person2';
import { Button, Fab } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useEffect, useRef, useState } from 'react';
import SidebarContent from './SidebarContent';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { useLogoutUser } from './OAuth/Logout';
import { useCheckIfLogged } from '../adapters/use-login';
import useRouter from '../adapters/userouter';

interface TProps {
  toOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Header({ toOpen }: TProps) {
  const handleLogout = useLogoutUser();
  const handleSetOpen = () => {
    toOpen((prev) => !prev);
  };
  const { goToPage } = useRouter();

  return (
    <>
      <div className=''>
        <header className='bg-[#292e32] p-5 flex justify-between'>
          <div className='flex items-center px-2'>
            <Button onClick={handleSetOpen}>
              <MenuIcon className='smmd:!block xl:!hidden px-3' />
            </Button>
          </div>
          <div className='px-2 flex items-center'>
            <div className='flex px-3 grow items-center'>
              <Button
                onClick={() => goToPage('/recarga/')}
                variant='contained'
                className='!bg-violet-600 !flex-shrink !min-w-min !p-1 !px-2'
              >
                <p className='text-white font-semibold text-[10px]'>
                  Fazer uma recarga
                </p>
              </Button>
            </div>
            <div>
              <Menu>
                <MenuButton
                  className='border-none'
                  style={{ background: 'transparent' }}
                >
                  <IconButton>
                    <Fab
                      size='small'
                      className='!grow'
                      style={{ backgroundColor: 'rgb(33, 37, 41' }}
                    >
                      <Person2Icon />
                    </Fab>
                  </IconButton>
                </MenuButton>
                <MenuItems
                  className='bg-[rgb(53,54,55)] bg-opacity-85 px-3 rounded transition duration-100 ease-out md:w-50 sm:w-40 sm:mt-1 md:mt-0 shadow-[0_5px_10px_#1a1d21] divide-y'
                  anchor='bottom end'
                  transition
                >
                  <MenuItem>
                    <Button
                      variant='text'
                      className='!text-white w-full !justify-start !text-[0.8rem] hover:bg-transparent'
                    >
                      <span className='font-semibold normal-case'>
                        Configurar conta
                      </span>
                    </Button>
                  </MenuItem>
                  <MenuItem>
                    <Button
                      onClick={handleLogout}
                      variant='text'
                      className='!text-white w-full !justify-start !text-[0.8rem] hover:bg-transparent'
                    >
                      <span className='text-red-400 font-semibold normal-case'>
                        Sair
                      </span>
                    </Button>
                  </MenuItem>
                </MenuItems>
              </Menu>
            </div>
          </div>
        </header>
      </div>
    </>
  );
}
