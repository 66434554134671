import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

export default function GlobalTextField(props: any) {
  const { control, register } = useFormContext();
  const {
    name = '',
    validators = {},
    className = 'disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500',
    key,
    label,
    placeholder = '',
    variant = 'outlined',
    type = 'text',
    size = 'medium',
    rows = 1,
    maxRows = 4,
    style,
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value }, fieldState }) => {
        return (
          <TextField
            {...register(name, validators)}
            className={className}
            key={key}
            label={label}
            placeholder={placeholder}
            variant={variant}
            size={size}
            rows={rows}
            maxRows={maxRows}
            style={style}
            type={type}
          />
        );
      }}
    />
  );
}
