import { useEffect } from 'react';
import useRouter from '../adapters/userouter';

export default function DefaultTemp() {
  const { goToPage } = useRouter();
  useEffect(() => {
    goToPage('/dashboard/');
  }, [goToPage]);
  return null;
}
