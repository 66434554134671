import React, {useEffect, useState} from 'react';
import GatewayCard from "../components/GatewayCard";
import axios from "axios";
import {CircularProgress} from "@mui/material";
import chronosQuery from "../adapters/chronos-query";


export default function Integration() {
    const [cardData, setCardData] = useState([]);
    const query = chronosQuery();
    // Fetch data from the API when the component mounts
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await query.get('/api/gateway')
                setCardData(response.data); // Ensure the response data structure matches what you expect
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="xl:ml-[280px] p-5">
            <h2 className="text-center text-3xl font-extrabold text-violet-500 mb-4">Gateways</h2>
            <p className="text-center text-gray-600 mb-8">
                Conecte-se com os gateways disponíveis e comece a gerar rastreios de forma automática.
            </p>
            <div className="flex flex-wrap justify-center gap-6">
                {cardData.length > 0 ? (
                    cardData.map((data, index) => (
                        <GatewayCard
                            key={index}
                            name={data.name}
                            logoUrl={data.logoUrl}
                        />
                    ))
                ) : (
                    <CircularProgress  color="secondary" />
                )}
            </div>
        </div>
    );
}

