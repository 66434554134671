import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import DataTable from '../components/DataGrid';
import PeopleIcon from '@mui/icons-material/People';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import SavingsIcon from '@mui/icons-material/Savings';
import { jwtDecode } from 'jwt-decode';
import useRouter from '../adapters/userouter';
import { useCallback, useEffect } from 'react';
import { Alert, Button } from '@mui/material';
import {toast} from "react-toastify";

interface TUserInfo {
  iss: string;
  sub: string;
  exp: string;
}

export default function Affiliates() {
  const { goToPage } = useRouter();
  const token = localStorage.getItem('token');

  if (!token) {
    goToPage('/login/');
    return null;
  }
  const affiliates = [
    {
      id: 1,
      Usuario: 'Ramonxx',
      Recargas: '200 Creditos',
      Comissao: '40 Creditos',
      Data: '31/02/2025',
    },
    {
      id: 2,
      Usuario: 'YagoMIR4',
      Recargas: '400 Creditos',
      Comissao: '80 Creditos',
      Data: '31/02/2025',
    },
    {
      id: 3,
      Usuario: 'Samuks',
      Recargas: '200 Creditos',
      Comissao: '40 Creditos',
      Data: '31/02/2025',
    },
    {
      id: 4,
      Usuario: 'MihAlves22',
      Recargas: '100 Creditos',
      Comissao: '20 Creditos',
      Data: '31/02/2025',
    },
  ];

  const userInfo: TUserInfo = jwtDecode(token);
  const userLink = `www.chronosrastreios.com/register/${userInfo?.sub}`;

  const iterateOver = [
    {
      title: 'Comissões recebidas',
      value: 4580,
      icon: <MonetizationOnIcon className='!text-green-400' />,
      color: 'text-green-400',
      backgroundColor: 'bg-green-400',
    },
    {
      title: 'Usuários Indicados',
      value: 49,
      icon: <PeopleIcon style={{ color: 'lightblue' }} />,
      color: 'text-blue-400',
      backgroundColor: 'bg-blue-400',
    },
    {
      title: 'Recargas efetuadas',
      value: 16,
      icon: <CreditCardIcon style={{ color: 'orange' }} />,
      color: 'text-orange-400',
      backgroundColor: 'bg-orange-400',
    },
    {
      title: 'Taxa de comissão',
      value: '20%',
      icon: <SavingsIcon style={{ color: 'yellow' }} />,
      color: 'text-yellow-400',
      backgroundColor: 'bg-yellow-400',
    },
  ];
  return (
    <>
      <div className='xl:ml-[280px] p-5'>
        <div className='flex flex-col mb-5'>
          <p className='m-0 text-xs text-[#878a99]'>
            Indique usuários e ganhe comissões
          </p>
        </div>
        <div className='grid sm:grid-cols-2 md:grid-cols-4 gap-4'>
          {iterateOver &&
            iterateOver.map((item) => (
              <>
                <div
                  className='rounded xl p-5 relative'
                  style={{ backgroundColor: 'rgb(41 46 50)' }}
                >
                  <h4 className={`m-0 ${item.color}`}>{item.title}</h4>
                  <p className={`${item.color}`}>{item.value}</p>
                  <div className='absolute bottom-0 right-0 pr-7 pb-3'>
                    <div
                      className={`p-3 mb-2 ${item.backgroundColor} bg-opacity-15 rounded flex`}
                    >
                      {item.icon}
                    </div>
                  </div>
                </div>
              </>
            ))}
        </div>
        <div className='relative'>
          <DataTable rows={affiliates} title={'Seus Afiliados'} />
        </div>
        <div className='mt-8'>
          <Alert variant='filled' severity='info'>
            <span className='font-bold'>Link de afiliação: {userLink}</span>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(userLink).then(() => {
                  // Show react-toastify notification
                  toast.success("Link copiado com sucesso!");
                }).catch(err => {
                  console.error("Failed to copy: ", err);
                  toast.error("Falha ao copiar o Link");
                });
              }}
            >
              <ContentCopyIcon className='!text-white !hover:bg-sky-800' />
            </Button>
          </Alert>
        </div>
      </div>
    </>
  );
}
