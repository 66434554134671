import * as React from 'react';
import {Button, Tooltip} from "@mui/material";
import ChronosTextField from '../components/ChronosTextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useState} from "react";
import ChronosForm from "./ChronosForm";
import chronosQuery from "../adapters/chronos-query";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface GatewayCardProps {
    name: string;
    logoUrl: string;
}


const GatewayCard: React.FC<GatewayCardProps> = ({name, logoUrl}) => {

    const [open, setOpen] = useState(false);
    const webhook = `https://monkfish-app-y2t3k.ondigitalocean.app/webhook/${name}`;
    const query = chronosQuery()
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    function handleFormSubmit(formData: any) {
        query.post(`/api/gateway/connectGateway/${name}`, {
            token: formData.Token
        }).then(() => {
            toast.success('Gateway conectado com sucesso');
        }).catch((error) => {
            toast.error('Erro ao conectar o gateway');
        })
    }

    return (
        <div
            className="bg-[#292e32] rounded-lg shadow-md p-6 w-44 transform transition duration-300 hover:-translate-y-2">
            <div className="flex justify-center items-center mb-4">
                <img src={logoUrl} className=' w-32 h-32 object-contain'/>
            </div>
            <Button
                className="!mt-6 !w-full !bg-violet-600 !font-black !py-2 !bg-opacity-45 !rounded hover:!bg-opacity-100"
                color="secondary"
                variant="contained"
                type="submit"
                onClick={handleClickOpen}
            >
                Conectar
            </Button>
            <ChronosForm validate='onBlur' formSubmitCallback={handleFormSubmit}>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            const formJson = Object.fromEntries((formData as any).entries());
                            const email = formJson.email;
                            console.log(email);
                            handleClose();
                        },
                        className: '!bg-[#292e32] !text-white !font-bold !w-full',
                    }}
                >
                    <DialogTitle> Integrando {name}</DialogTitle>
                    <DialogContent>
                        <DialogContentText className='!text-white'>
                            Para realizar a integração clique no icone abaixo e cole o link no campo webhooks no seu gateway, após isso insira o token secreto!
                        </DialogContentText>
                        <ChronosTextField
                            className='text-violet-600 !w-full'
                            autoFocus
                            required
                            margin="dense"
                            id="name"
                            name="Token"
                            label="Token"
                            type="password"
                            fullWidth
                            variant="standard"
                        />
                    </DialogContent>
                    <DialogActions >
                        <DialogContentText >
                            <Tooltip className='!bg-violet-600 !bg-opacity-45 !rounded hover:!bg-opacity-100'
                                     title={webhook}
                                     placement="top">
                                <Button className='!text-white' onClick={() => {
                                    navigator.clipboard.writeText(webhook).then(() => {
                                        // Show react-toastify notification
                                        toast.success("Link copiado com sucesso!");
                                    }).catch(err => {
                                        console.error("Failed to copy: ", err);
                                        toast.error("Falha ao copiar o Link");
                                    });
                                }}>Webhook <ContentCopyIcon className="text-white"/></Button>
                            </Tooltip>
                        </DialogContentText>
                        <Button
                            className='!bg-violet-600 !text-white !font-medium !bg-opacity-45 !rounded hover:!bg-opacity-100'
                            onClick={handleClose}>Cancelar</Button>
                        <Button
                            className='!bg-violet-600 !text-white !font-medium !bg-opacity-45 !rounded hover:!bg-opacity-100'
                            type="submit">Conectar</Button>
                    </DialogActions>
                </Dialog>
            </ChronosForm>
        </div>
    )
        ;
}
export default GatewayCard;
