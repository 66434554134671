import React, { useCallback, useEffect, useMemo } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Login from './views/Login';
import Register from './views/Register';
import { RecoverPassword } from './views/RecoverPassword';
import DefaultTemp from './views/DefaultTemp';
import Panel from './views/Panel';
import { useCheckIfLogged } from './adapters/use-login';
import useRouter from './adapters/userouter';
import OutletHeader from './components/OutletHeader';
import PricingSection from './views/PricingSection';
import Affiliates from './views/Affiliates';
import Integration from './views/Integration';
import Orders from './views/Orders';

function App() {
  const allowedUrls = ['login', 'recoverpassword', 'register'];
  const { goToPage } = useRouter();
  const uselocation = useLocation();
  const isLogged = useCheckIfLogged();
  const location = uselocation.pathname.replace(/\/+/g, '');
  useEffect(() => {
    if (!allowedUrls.includes(location)) {
      if (!isLogged) {
        goToPage('/login');
      }
    }
  }, [location]);

  return (
    <Routes>
      <Route path={''} element={<DefaultTemp />} />
      <Route path={'/'} element={<DefaultTemp />} />
      <Route path={'/login'} element={<Login />} />
      <Route path={'/register'} element={<Register />} />
      <Route path={'/recoverpassword'} element={<RecoverPassword />} />
      <Route element={<OutletHeader />}>
        <Route path={'/dashboard'} element={<Panel />} />
        <Route path={'/recarga'} element={<PricingSection />} />
        <Route path={'/affiliates'} element={<Affiliates />} />
        <Route path={'/integrate'} element={<Integration />} />
        <Route path={'/pedidos'} element={<Orders />} />
      </Route>
    </Routes>
  );
}

export default App;
