import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useMemo } from 'react';

export default function useRouter() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { search } = location;

  return useMemo(() => {
    const goToPage = (route: any) => {
      navigate(route, { replace: true });
    };
    const urlParams = new URLSearchParams(search);
    return {
      push: (path) => navigate(path),
      replace: (path) => navigate(path, { replace: true }),
      pathname: location.pathname,
      params,
      searchParams: Object.fromEntries(urlParams.entries()),
      location,
      navigate,
      goToPage,
    };
  }, [params, location, navigate, search]);
}
