import { useEffect, useRef, useState } from 'react';
import SidebarContent from './SidebarContent';
import { forwardRef } from 'react';

const Sidebar = forwardRef<HTMLDivElement, { isOpen: boolean }>(
  ({ isOpen }, ref) => {
    return (
      <div
        ref={ref}
        className={`top-0 left-0 z-10 fixed !h-screen max-w-fit !transition-transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} xl:translate-x-0`}
        style={{
          background: '#212529',
          boxShadow: '4px 0 8px rgba(0, 0, 0, 0.2)',
        }}
      >
        <SidebarContent />
      </div>
    );
  },
);
export default Sidebar;
