import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import { InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';

interface DataTableProps {
  rows: any[];
  title?: string;
}

function getRowId(row: any) {
  return row.usuario || row.id || row.produto;
}

const generateColumns = (rows: any[]): GridColDef[] => {
  if (rows.length === 0) return [];

  const keys = Object.keys(rows[0]);
  return keys.map((key) => ({
    field: key,
    headerName: key.charAt(0).toUpperCase() + key.slice(1),
    flex: 1,
    headerClassName: 'column-header',
    sortable: false,
  }));
};

const paginationModel = { page: 0, pageSize: 5 };

const DataTable = ({ rows, title, shown = false }) => {
  const columns = generateColumns(rows);
  const [value, setValue] = useState(rows);
  const handleFilter = async (rowsValue) => {
    const filteredData = await rows.filter((item) =>
      Object.values(item).some((value) =>
        value.toString().toLowerCase().includes(rowsValue.toLowerCase()),
      ),
    );
    setValue(filteredData);
  };
  return (
    <>
      <div className='bg-[#32383e] rounded border-b-2 p-2 mt-3 -mb-8'>
        <div className='flex w-full rounded flex items-center text-md'>
          <div className='flex-1'>
            <h3 className='pl-3 pr-5'>
              <p className='text-[lightgrey]'>{title}</p>
            </h3>
          </div>
          {shown && (
            <div className='flex flex-1 border border-solid bg-[rgb(41,47,54)] rounded'>
              <SearchIcon className='p-3' />
              <InputBase
                className=''
                placeholder='Pesquise por Rastreio, Nome, CPF'
                fullWidth
                onChange={(e) => handleFilter(e.target.value)}
              />
            </div>
          )}
        </div>
      </div>
      <div className='mt-3 pt-2 rounded border-b-2'>
        <Paper className='!max-w-full'>
          <DataGrid
            rows={value}
            columns={columns}
            getRowId={getRowId}
            initialState={{ pagination: { paginationModel } }}
            pageSizeOptions={[5, 10]}
            columnVisibilityModel={{
              id: false,
            }}
            disableColumnFilter
            disableColumnMenu
            className='mt-3'
            disableRowSelectionOnClick
            disableMultipleRowSelection
            disableDensitySelector
            slotProps={{
              pagination: {
                labelRowsPerPage: '',
              },
            }}
            sx={{
              border: 0,
              backgroundColor: '#282b2e',
              '& .MuiDataGrid-cell': {
                borderTop: '1px solid grey',
                color: 'rgb(206 212 218)',
              },
              '& .column-header': {
                backgroundColor: '#282b2e',
                color: 'rgb(152,152,152,1)',
                borderTop: '1px solid grey',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '1rem',
                fontWeight: 'bold',
              },
            }}
          />
        </Paper>
      </div>
    </>
  );
};

export default DataTable;
