import logo from '../assets/logo.png';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@mui/material';
import ChronosForm from '../components/ChronosForm';
import ChronosTextField from '../components/ChronosTextField';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import useRouter from '../adapters/userouter';
import chronosQuery from '../adapters/chronos-query';
import { toast } from 'react-toastify';

export default function Register() {
  const { referral } = useParams<{ referral: string }>();
  const { goToPage } = useRouter();
  const query = chronosQuery();
  const handleFormSubmit = async (formData: any) => {
    const data = {
      name: formData.username,
      username: formData.username,
      email: formData.email,
      password: formData.password,
      referral: referral || null,
    };
    try {
      const result = await query.post('/api/user/register', data);
      console.log(result);
      return result;
    } catch (error) {
      toast.error('Não foi possível criar a conta!');
    }
  };
  return (
    <ChronosForm validate='onBlur' formSubmitCallback={handleFormSubmit}>
      <div>
        <div className='mt-20 flex justify-center'>
          <img width='200' height='50' src={logo} alt='logo' />
        </div>
        <div className='mt-4  flex justify-center'>
          <h1 className='text-3xl font-extrabold text-violet-500'>
            Criar nova conta
          </h1>
        </div>
        <div className=' mb-4 flex justify-center items-center'>
          <span className='text-lg text-gray-50 pr-2'>ou </span>
          <a
            className='no-underline text-violet-500'
            onClick={() => goToPage('/login')}
          >
            Entrar na sua conta
          </a>
        </div>
        <div className='max-w-md px-4 md:px-8 py-8 bg-neutral-800 flex flex-col mx-auto rounded-3xl'>
          <ChronosTextField
            className='w-full !mb-5 bg-neutral-600 rounded-md'
            label='Usuário'
            placeholder='Usuário'
            id='filled-basic'
            variant='outlined'
            name='username'
          />

          <ChronosTextField
            className='w-full !mb-5 bg-neutral-600 rounded-md'
            label='E-mail'
            placeholder='E-mail'
            id='filled-basic'
            variant='outlined'
            name='email'
          />

          <ChronosTextField
            className='w-full !mb-5 bg-neutral-600 rounded-md'
            label='Senha'
            placeholder='Senha'
            id='filled-basic'
            variant='outlined'
            type='password'
            name='password'
          />

          <ChronosTextField
            className='w-full mt-5 bg-neutral-600 rounded-md'
            label='Repetir senha'
            placeholder='Repetir senha'
            id='filled-basic'
            variant='outlined'
            type='password'
            name='confirmPassword'
          />

          <FormGroup className='mt-5'>
            <label></label>
            <FormControlLabel
              className='  !text-gray-300'
              control={<Checkbox />}
              label='Eu li e aceito os termos de uso'
            />
          </FormGroup>

          <Button
            className='!mt-12 !font-black !bg-violet-700 h-10 !text-white !hover:bg-violet-600'
            color='secondary'
            variant='contained'
            type='submit'
          >
            Criar conta
          </Button>
        </div>
        <div className=' mt-24 h-0.5 w-screen bg-violet-600'></div>
        <div className='mt-5'>
          <span className='text-gray-700 flex justify-center'>
            @2024 Chronos Rastreios
          </span>
        </div>
        <div className='mt-4 text-xs'>
          <span className='text-gray-700 flex justify-center'>
            Chronos Rastreios @Todos os direitos reservados
          </span>
        </div>
      </div>
    </ChronosForm>
  );
}
