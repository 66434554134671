import axios from 'axios';

export default function chronosQuery() {
  const URL = process.env.REACT_APP_BASE_URL;
  const api = axios.create({ baseURL: URL });
  function post(url: string, payload, config?) {
    const result = api.post(url, payload, config);
    return result;
  }
  function get(url: string) {
    const result = api.get(url);
    return result;
  }
  function patch(url: string, payload, config?) {
    const result = api.patch(url, payload, config);
    return result;
  }
  return {
    post,
    get,
    patch,
  };
}
