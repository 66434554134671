import React from 'react';
import DataTable from '../components/DataGrid';
import useRouter from '../adapters/userouter';
import { jwtDecode } from 'jwt-decode';
export default function Orders() {
  interface TUserInfo {
    nickname: string;
    email: string;
    name: string;
  }

  const orders = [
    {
      id: 1,
      Produto: 'Pedido 1',
      Valor: 'R$ 100,00',
      integracao: 'Bynet',
      status: 'Enviado',
    },
    {
      id: 3,
      Produto: 'Pedido 2',
      Valor: 'R$ 150,00',
      integracao: 'PagouAi',
      status: 'Enviado',
    },
    {
      id: 4,
      Produto: 'Pedido 3',
      Valor: 'R$ 150,00',
      integracao: 'PagueSafe',
      status: 'Enviado',
    },
    {
      id: 5,
      Produto: 'Pedido 4',
      Valor: 'R$ 150,00',
      integracao: 'BrazaPay',
      status: 'Enviado',
    },
  ];

  return (
    <div className='xl:ml-[280px] p-5'>
      <div className='flex flex-col mb-5'>
        <p className='m-0 text-base text-[#878a99]'>
          Acompanhe aqui os seus pedidos!
        </p>
      </div>
      <DataTable rows={orders} title={'Pedidos'} shown />
    </div>
  );
}
