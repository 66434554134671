import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import DataTable from '../components/DataGrid';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import BarChartIcon from '@mui/icons-material/BarChart';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import SavingsIcon from '@mui/icons-material/Savings';
import DoneIcon from '@mui/icons-material/Done';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { jwtDecode } from 'jwt-decode';
import useRouter from '../adapters/userouter';
import { useCallback, useEffect } from 'react';
import { GridColDef } from '@mui/x-data-grid';

interface TUserInfo {
  iss: string;
  sub: string;
  exp: string;
}

export default function Panel() {
  const { goToPage } = useRouter();
  const token = localStorage.getItem('token');

  if (!token) {
    goToPage('/login/');
    return null;
  }

  const userInfo: TUserInfo = jwtDecode(token);
  const orders = [
    {
      id: 1,
      Produto: 'Pedido 1',
      Valor: 'R$ 100,00',
      integracao: 'Bynet',
      status: 'Enviado',
    },
    {
      id: 2,
      Produto: 'Pedido 2',
      Valor: 'R$ 150,00',
      integracao: 'PagouAi',
      status: 'Enviado',
    },
    {
      id: 2,
      Produto: 'Pedido 3',
      Valor: 'R$ 150,00',
      integracao: 'PagueSafe',
      status: 'Enviado',
    },
    {
      id: 2,
      Produto: 'Pedido 4',
      Valor: 'R$ 150,00',
      integracao: 'BrazaPay',
      status: 'Enviado',
    },
  ];
  const iterateOver = [
    {
      title: 'Créditos',
      value: 0,
      icon: <MonetizationOnIcon className='!text-green-400' />,
      color: 'text-green-400',
      backgroundColor: 'bg-green-400',
    },
    {
      title: 'Pedidos Aguardando postagem',
      value: 0,
      icon: <HourglassEmptyIcon className='text-green-400' />,
      color: 'text-green-400',
      backgroundColor: 'bg-green-400',
    },
    {
      title: 'Total de rastreios enviados',
      value: 0,
      icon: <BarChartIcon className='text-purple-400' />,
      color: 'text-purple-400',
      backgroundColor: 'bg-purple-400',
    },
    {
      title: 'Pedidos Atrasados por falta de creditos',
      value: 0,
      icon: <WarningAmberIcon className='text-red-500' />,
      color: 'text-red-500',
      backgroundColor: 'bg-red-500',
    },
    {
      title: 'Rastreios Finalizados',
      value: 0,
      icon: <DoneIcon className='text-orange-400' />,
      color: 'text-orange-400',
      backgroundColor: 'bg-orange-400',
    },
    {
      title: 'Faturamento total',
      value: 0,
      icon: <SavingsIcon className='text-orange-400' />,
      color: 'text-orange-400',
      backgroundColor: 'bg-orange-400',
    },
    {
      title: 'Rastreios enviados hoje',
      value: 0,
      icon: <LocalShippingIcon className='text-blue-400' />,
      color: 'text-blue-400',
      backgroundColor: 'bg-blue-400',
    },
    {
      title: 'Pedidos recebidos hoje',
      value: 0,
      icon: <LocalFireDepartmentIcon className='text-blue-400' />,
      color: 'text-blue-400',
      backgroundColor: 'bg-blue-400',
    },
  ];
  return (
    <>
      <div className='xl:ml-[280px] p-5'>
        <div className='flex flex-col mb-5'>
          <h3 className='m-0 text-[#ced4da]'>
            Bem-Vindo,{' '}<span className='text-[#7250ef]'>{userInfo?.sub}</span>
          </h3>
          <p className='m-0 text-xs text-[#878a99]'>
            Acompanhe aqui as estatísticas de seu painel!
          </p>
        </div>
        <div className='grid sm:grid-cols-1 exsm:grid-cols-2 smmd:grid-cols-2 xl:grid-cols-4 gap-4 '>
          {iterateOver &&
            iterateOver.map((item) => (
              <>
                <div
                  className='rounded xl p-5 relative transform transition duration-300 hover:-translate-y-2'
                  style={{ backgroundColor: 'rgb(41 46 50)' }}
                >
                  <h4 className={`m-0 ${item.color}`}>{item.title}</h4>
                  <p className={`${item.color}`}>{item.value}</p>
                  <div className='absolute bottom-0 right-0 pr-7 pb-3'>
                    <div
                      className={`p-3 mb-2 ${item.backgroundColor} bg-opacity-15 rounded flex`}
                    >
                      {item.icon}
                    </div>
                  </div>
                </div>
              </>
            ))}
        </div>
      </div>
    </>
  );
}
