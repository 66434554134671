import logo from '../assets/logo.png';
import { Button } from '@mui/material';
import ChronosForm from '../components/ChronosForm';
import ChronosTextField from '../components/ChronosTextField';
import axios from 'axios';
import chronosQuery from '../adapters/chronos-query';
import { jwtDecode } from 'jwt-decode';
import { useCheckIfLogged } from '../adapters/use-login';
import useRouter from '../adapters/userouter';
import { Bounce, toast } from 'react-toastify';
import { useEffect } from 'react';

export default function Login() {
  const { goToPage } = useRouter();
  const query = chronosQuery();
  const isLogged = useCheckIfLogged();

  useEffect(() => {
    if (isLogged) {
      toast.warning('Usuário já logado, deslogue e tente novamente');

      goToPage('/dashboard/');
    }
  }, []);
  const handleLogin = async (username: string, password: string) => {
    try {
      const response = await query.post('/api/user/login', {
        username: username,
        password: password,
      });

      localStorage.setItem('token', response.data);

      goToPage('/dashboard');
    } catch (error) {
      toast.error('Usuário ou senha incorreto(s)', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        transition: Bounce,
      });
    }
  };

  function handleFormSubmit(formData: any) {
    const response = handleLogin(formData.username, formData.password);
  }

  return (
    <ChronosForm validate='onBlur' formSubmitCallback={handleFormSubmit}>
      <div>
        <div className='mt-20 flex justify-center'>
          <img width='200' height='50' src={logo} alt='logo' />
        </div>
        <div className='mt-4  flex justify-center'>
          <h1 className='text-3xl font-extrabold text-violet-500'>
            Entrar na sua conta
          </h1>
        </div>
        <div className=' mb-4 flex justify-center items-center'>
          <span className='text-lg text-gray-50 pr-2'>ou </span>
          <a
            className='no-underline text-violet-500'
            onClick={() => goToPage('/register/')}
          >
            {' '}
            fazer cadastro
          </a>
        </div>
        <div className='max-w-md px-4 md:px-8 py-8 bg-neutral-800 flex flex-col mx-auto rounded-3xl'>
          <ChronosTextField
            className='!px-22 mb-5 bg-neutral-600 !rounded-lg'
            label='Usuário'
            placeholder='Usuário'
            id='filled-basic'
            variant='outlined'
            name='username'
          />

          <ChronosTextField
            className='!mt-10 bg-neutral-600 !rounded-lg'
            label='Senha'
            placeholder='Senha'
            id='filled-basic'
            variant='outlined'
            type='password'
            name='password'
          />
          <div className='mt-4' style={{ float: 'right' }}>
            <a
              className=' text-sm font-bold text-violet-500 no-underline'
              onClick={() => goToPage('/recoverpassword/')}
            >
              Esqueceu sua senha?
            </a>
          </div>

          <Button
            className='!mt-12 !font-black !bg-violet-600 !h-10 '
            color='secondary'
            variant='contained'
            type='submit'
          >
            Entrar
          </Button>
        </div>

        <div className='fixed bottom-0'>
          <div className='mt-24 h-0.5 w-screen bg-violet-600'></div>
          <div className='mt-5'>
            <span className='text-gray-700 flex justify-center'>
              @2024 Chronos Rastreios
            </span>
          </div>
          <div className='mt-4 text-xs'>
            <span className='text-gray-700 flex justify-center'>
              Chronos Rastreios @Todos os direitos reservados
            </span>
          </div>
        </div>
      </div>
    </ChronosForm>
  );
}
