import React from 'react';
import PriceCards from '../components/PriceCards';

const PricingSection = () => {
  // Example data for the cards
  const cardData = [
    {
      credits: '50 CRÉDITOS',
      price: 'R$ 50,00',
      details: 'fifty',
    },
    {
      credits: '100 CRÉDITOS',
      price: 'R$ 100,00',
      details: 'hundred',
    },
    {
      credits: '600 CRÉDITOS',
      price: 'R$ 500,00',
      details: 'five_hundred',
    },
    {
      credits: '1200 CRÉDITOS',
      price: 'R$ 1000,00',
      details: 'thousand',
    },
    {
      credits: '2400 CRÉDITOS',
      price: 'R$ 2000,00',
      details: 'two_thousand',
    },
    {
      credits: '6000 CRÉDITOS',
      price: 'R$ 5000,00',
      details: 'five_thousand',
    },
    {
      credits: '12000 CRÉDITOS',
      price: 'R$ 10000,00',
      details: 'ten_thousand',
    },
    {
      credits: '24000 CRÉDITOS',
      price: 'R$ 20000,00',
      details: 'twenty_thousand',
    },
  ];

  return (
    <div className='flex flex-col p-5'>
      <div className='xl:ml-[250px]'>
        <h2 className='text-center text-3xl font-extrabold text-violet-500 mb-4 px-5'>
          Recarregar Créditos
        </h2>
        <p className='text-center text-gray-600 mb-8 px-5'>
          Selecione o seu pacote e continue aproveitando todos os serviços que a
          chronos rastreios tem a oferecer.
        </p>
      </div>
      <div className='xl:ml-[280px]  gap-6 grid sm:grid-cols-1 exsm:grid-cols-2 md:grid-cols-2 smmd:grid-cols-4 md:grid-cols-3 xl:grid-cols-4 xl:ml-[300px]'>
        {cardData.map((data, index) => (
          <PriceCards
            key={index}
            credits={data.credits}
            price={data.price}
            details={data.details}
          />
        ))}
      </div>
    </div>
  );
};

export default PricingSection;
