import logo from '../assets/logo.png';
import { Alert, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useEffect, useState } from 'react';
import ChronosForm from '../components/ChronosForm';
import ChronosTextField from '../components/ChronosTextField';
import axios from 'axios';
import useRouter from '../adapters/userouter';

type AlertSeverity = 'success' | 'error' | 'info' | 'warning';

export function RecoverPassword() {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { goToPage } = useRouter();
  const [hidden, setHidden] = useState(true);
  const [emailResponse, setEmailResponse] = useState<boolean | null>(null);
  const [alertContent, setAlertContent] = useState<{
    severity: AlertSeverity;
    message: string;
  }>({
    severity: 'success',
    message: '',
  });

  const handleAlert = () => {
    setHidden(false);
    setTimeout(() => {
      setHidden(true);
      setEmailResponse(null);
    }, 3000);
  };

  const handleFormSubmit = (formData: any) => {
    axios
      .post(`${baseUrl}/api/user/recover-password?email=${formData.email}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setEmailResponse(true);
      })
      .catch((error) => {
        setEmailResponse(false);
      });
  };

  useEffect(() => {
    if (emailResponse !== null) {
      setAlertContent(getAlert(emailResponse));
      handleAlert();
    }
  }, [emailResponse]);

  function getAlert(input: boolean) {
    if (input) {
      return {
        severity: 'success' as AlertSeverity,
        message: 'Email de recuperação de senha enviado com sucesso!',
      };
    } else {
      return {
        severity: 'error' as AlertSeverity,
        message: 'Erro ao enviar email de recuperação de senha!',
      };
    }
  }

  return (
    <ChronosForm validate='onBlur' formSubmitCallback={handleFormSubmit}>
      <div className='flex flex-col min-h-screen'>
        <div className='flex-grow'>
          <div className='mt-20 flex justify-center'>
            <img width='200' height='50' src={logo} alt='logo' />
          </div>
          <div className='mt-4 flex justify-center'>
            <h1 className='text-3xl font-extrabold text-violet-500'>
              Recuperar senha
            </h1>
          </div>
          <div className='mb-4 flex justify-center items-center'>
            <span className='text-lg text-gray-50 pr-2'>ou </span>
            <a
              className='no-underline text-violet-500'
              onClick={() => goToPage('/login')}
            >
              Entrar na sua conta
            </a>
          </div>
          <div className='max-w-md px-4 md:px-8 py-8 bg-neutral-800 flex flex-col mx-auto rounded-3xl'>
            <ChronosTextField
              className='w-full !mb-5 bg-neutral-600 rounded-md'
              label='E-mail'
              placeholder='E-mail'
              id='filled-basic'
              variant='outlined'
              name='email'
            />
            <Button
              className='!mt-2 !font-black !bg-violet-700 h-10 !text-white !hover:bg-violet-600'
              color='secondary'
              variant='contained'
              type='submit'
            >
              Recuperar senha
            </Button>
          </div>
          <div
            className={`fixed top-5 right-5 transition-opacity duration-500 ${
              hidden ? 'opacity-0' : 'opacity-100'
            }`}
          >
            <Alert severity={alertContent.severity}>
              {alertContent.message}
            </Alert>
          </div>
        </div>
        <div className='mt-24 h-0.5 w-screen bg-violet-600'></div>
        <div className='mt-5'>
          <span className='text-gray-700 flex justify-center'>
            @2024 Chronos Rastreios
          </span>
        </div>
        <div className='mt-4 text-xs'>
          <span className='text-gray-700 flex justify-center'>
            Chronos Rastreios @Todos os direitos reservados
          </span>
        </div>
      </div>
    </ChronosForm>
  );
}
